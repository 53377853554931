import PropTypes from 'prop-types';
import { Checkbox, Select } from 'sw-ui';
import Autocomplete from 'react-autocomplete';

import Input from 'components/input';

import {
  CONTACTS_FIELDS,
  INPUT_PROPS,
  REQUEST_STATUS_LABELS,
  LABELS,
  THEME,
  WRAP_STYLE,
  REQUEST_STATUSES,
  VAT_VALUES,
} from 'bi/constants/hotelBookRequest';
import { FIELDS } from 'bi/constants/hotels';

import styles from './index.module.css';

const HotelGeneralInfo = (props) => {
  const {
    changeVatValues,
    searchHotelsInput,
    changeField,
    requestHotelInfo,
    changeSelectedHotel,
    selectedHotel,
    changeHotelInput,
    contacts,
    isAdditionalEmail,
    changeAdditionalEmail,
    isSelfEmployed,
    changeIsSelfEmployed,
    isEditing,
    status,
    requestStatus,
    changeRequestStatus,
    setContacts,
    vatAmount,
    hotelInfo,
    setHotelId,
  } = props;

  const handleSelectHotel = (value, item) => {
    if (!value) return;

    if (value !== selectedHotel) {
      setHotelId(0);
    }

    changeField(value, FIELDS.SEARCH.VALUE);
    changeSelectedHotel(value);
    requestHotelInfo(item);
  };

  const renderMenu = (items) => (
    <div className={ styles.autocomplete }>
      <div className={ styles.items_wrap }>
        { items }
      </div>
    </div>
  );

  const renderItem = (item) => (item.IsRegion
    ? <div key={ item.Id } />
    : (
      <div key={ item.Id } className={ styles.autocomplete_item }>
        <div className={ styles.autocomplete_item_text }>{ item.Name }</div>
        <div className={ styles.autocomplete_item_label }>{ item.FullName }</div>
      </div>
    ));

  const renderAutoComplete = () => {
    const { value: currentValue, suggest } = searchHotelsInput;

    return (
      <Autocomplete
        value={ currentValue }
        inputProps={ INPUT_PROPS }
        items={ suggest }
        getItemValue={ (item) => item.Name }
        onSelect={ (value, item) => handleSelectHotel(value, item) }
        onChange={ ({ target: { value } }) => changeHotelInput(value) }
        renderItem={ (item) => renderItem(item) }
        renderMenu={ (items) => renderMenu(items) }
        wrapperStyle={ WRAP_STYLE }
      />
    );
  };

  const renderAdditionalEmail = () => {
    const additionalInput = isAdditionalEmail
      ? (
        <Input
          type={ CONTACTS_FIELDS.ADDITIONAL_EMAIL }
          field={ CONTACTS_FIELDS.ADDITIONAL_EMAIL }
          placeholder={ LABELS.EMAIL }
          value={ contacts.additionalEmail }
          onChange={ ({ target: { value } }) => setContacts(CONTACTS_FIELDS.ADDITIONAL_EMAIL, value) }
          className={ styles.input }
        />
      )
      : null;

    return (
      <div className={ styles.condition }>
        <Checkbox
          value={ isAdditionalEmail }
          onChange={ () => changeAdditionalEmail(!isAdditionalEmail) }
          className={ styles.checkbox_width }
        >
          { LABELS.ADDITIONAL_EMAIL }
        </Checkbox>
        { additionalInput }
      </div>
    );
  };

  const renderConditionCheckbox = (condition, fn, label) => (
    <Checkbox
      value={ condition }
      onChange={ () => fn(!condition) }
      className={ styles.checkbox_width }
    >
      { label }
    </Checkbox>
  );

  const renderStatusSelector = () => {
    const isValidStatus = status && isEditing && (status === REQUEST_STATUS_LABELS.Pending);

    if (!isValidStatus) return null;

    return (
      <Select
        label={ LABELS.REQUEST_STATUS }
        items={ REQUEST_STATUSES }
        value={ requestStatus }
        onChange={ changeRequestStatus }
        className={ styles.hotel_item_selector }
        theme={ THEME.BORDER }
        withLabel
        smooth
        overflow
      />
    );
  };

  const renderContactsInfo = () => {
    const email = contacts.email;
    const phone = hotelInfo.phone || contacts.phone;

    return (
      <div className={ `${styles.contacts} ${styles.divider}` }>
        <Input
          type={ CONTACTS_FIELDS.EMAIL }
          field={ CONTACTS_FIELDS.EMAIL }
          placeholder={ LABELS.EMAIL }
          value={ email }
          onChange={ ({ target: { value } }) => setContacts(CONTACTS_FIELDS.EMAIL, value) }
          className={ `${styles.width} ${styles.input}` }
        />
        <Input
          type={ CONTACTS_FIELDS.PHONE }
          field={ CONTACTS_FIELDS.PHONE }
          placeholder={ LABELS.PHONE }
          value={ phone }
          onChange={ ({ target: { value } }) => setContacts(CONTACTS_FIELDS.PHONE, value) }
          className={ `${styles.width} ${styles.input}` }
        />
      </div>
    );
  };

  return (
    <>
      <div className={ `${styles.hotel_wrap} ${styles.divider}` }>
        { renderAutoComplete() }
        <div className={ styles.hotel_item }>
          <Select
            label={ LABELS.TYPE_OF_VAT }
            items={ VAT_VALUES }
            value={ vatAmount }
            onChange={ changeVatValues }
            className={ styles.hotel_item_selector }
            theme={ THEME.BORDER }
            withLabel
            smooth
            overflow
          />
        </div>
        { renderConditionCheckbox(isSelfEmployed, changeIsSelfEmployed, LABELS.SELF_EMPLOYED) }
        { renderStatusSelector() }
      </div>
      { renderContactsInfo() }
      <div className={ `${styles.hotel_conditions} ${styles.divider}` }>
        { renderAdditionalEmail() }
      </div>
    </>
  );
};

HotelGeneralInfo.propTypes = {
  status: PropTypes.string,
  isEditing: PropTypes.bool.isRequired,
  changeVatValues: PropTypes.func.isRequired,
  searchHotelsInput: PropTypes.object.isRequired,
  selectedHotel: PropTypes.string,
  changeField: PropTypes.func.isRequired,
  requestHotelInfo: PropTypes.func.isRequired,
  changeSelectedHotel: PropTypes.func.isRequired,
  changeHotelInput: PropTypes.func.isRequired,
  contacts: PropTypes.object,
  setContacts: PropTypes.func.isRequired,
  isAdditionalEmail: PropTypes.bool.isRequired,
  changeAdditionalEmail: PropTypes.func.isRequired,
  isSelfEmployed: PropTypes.bool,
  changeIsSelfEmployed: PropTypes.func.isRequired,
  requestStatus: PropTypes.number,
  changeRequestStatus: PropTypes.func.isRequired,
  setHotelId: PropTypes.func.isRequired,
  hotelInfo: PropTypes.object,
  vatAmount: PropTypes.number,
};

export default HotelGeneralInfo;
