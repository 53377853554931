import { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  MultiSelect,
  Select,
  Checkbox,
} from 'sw-ui';
import DatePicker from 'react-datetime';

import SelectDepartment from 'modules/trip/components/selectDepartment';
import SelectProject from 'modules/trip/components/selectProject';
import Input from 'components/input';
import { Button } from 'components/button';
import HotelAnalyticsRow from '../HotelAnalyticsRow';

import {
  isBeforeDate,
  isSameDate,
} from '../../../../../../bi/utils/formatDate';

import { findDepartments } from 'bi/utils/trip';

import {
  BUTTON_LABELS,
  CANCELLATION_PENALTIES,
  COMMISSION,
  DATE_FIELDS,
  LABELS,
  PRICE_VALUES,
  REQUEST_STATUS_LABELS,
  THEME,
  WARNINGS,
  NOTIFICATION_STATE,
  REQUEST_STATUSES,
} from 'bi/constants/hotelBookRequest';
import { DEFAULTDATE, DEFAULTTIME } from 'constants/time';

import styles from './index.module.css';

export const HotelRoomBlock = (props) => {
  const {
    status,
    isEditing,
    requestStatus,
    customer,
    companyId,
    SelectedDepartmentId,
    setSelectedDepartment,
    customersList,
    customerProjects,
    projectId,
    MandatoryProject,
    setProjectId,
    selectedCustomers,
    setSelectedCustomers,
    prices,
    changePrices,
    changeCancellationPrices,
    roomTypes,
    selectedRooms,
    changeSelectedRooms,
    commission,
    setCommission,
    checkinDate,
    checkoutDate,
    changeCheckDates,
    hasEarlyCheckin,
    changeHasEarlyCheckin,
    hasLateCheckout,
    changeHasLateCheckout,
    hasBreakfast,
    changeHasBreakfast,
    checkinTime,
    changeCheckinTime,
    checkoutTime,
    changeCheckoutTime,
    hasFreeCancellation,
    changeHasFreeCancellation,
    freeCancellationDate,
    cancellationPenalties,
    onAddRoom,
    onDeleteRoom,
    onCopyRoom,
    roomIndex,
    totalRooms,
    getMandatoryProject,
    getCustomerInfo,
    getSelectedCustomersByIds,
    accountId,
    handleSendNotification,
    accountAnalytics,
    selectedRoomAnalytics,
    setCustomRoomAnalytics,
    isTripPaidByPersonalFunds,
  } = props;

  useEffect(() => {
    if (!hasEarlyCheckin) {
      changePrices(0, PRICE_VALUES.earlyCheckinPrice, '');
    }

    if (!hasLateCheckout) {
      changePrices(0, PRICE_VALUES.lateCheckoutPrice, '');
    }

    if (!hasBreakfast) {
      changePrices(0, PRICE_VALUES.breakfastPrice, '');
    }
  }, [
    hasEarlyCheckin,
    hasLateCheckout,
    hasBreakfast,
  ]);

  useEffect(() => {
    const getEmployeeInfo = (employees) => {
      if (!employees || !employees[0]) return;
      getCustomerInfo(roomIndex, employees[0]);
    };

    if (selectedCustomers) {
      getEmployeeInfo(selectedCustomers);
      getMandatoryProject(accountId);
      getSelectedCustomersByIds();
    }
  }, [selectedCustomers]);

  useEffect(() => {
    const validateSelections = () => {
      if (isSameDate(checkinDate, checkoutDate)) {
        return;
      }

      if (isBeforeDate(checkoutDate, checkinDate)) {
        return handleSendNotification(WARNINGS.DATE_WARNING, NOTIFICATION_STATE.FAILURE);
      }
    };

    validateSelections();
  }, [checkinDate, checkoutDate]);

  const renderSelectProject = () => {
    if (isTripPaidByPersonalFunds) return null;

    return (
      <SelectProject
        disabled={ !customerProjects }
        currentProjectId={ projectId }
        mandatoryProject={ MandatoryProject }
        projects={ customerProjects }
        onSelect={ (values) => setProjectId(values, roomIndex) }
      />
    );
  };

  const renderConfirmedRequestData = () => {
    const isConfirmed = status === REQUEST_STATUS_LABELS.Confirmed || requestStatus === REQUEST_STATUSES[1].value;

    if (!customer) {
      return;
    }

    const { Id = 0 } = customer;
    const userDepartments = findDepartments(customer, companyId);
    const departmentId = userDepartments.length > 0 ? userDepartments[0].Id : 0;
    const depId = departmentId || SelectedDepartmentId;

    if (!isConfirmed || !isEditing) return null;

    return (
      <div className={ styles.selectors }>
        <SelectDepartment
          isHotel
          departments={ userDepartments }
          employeesList={ customersList }
          currentDepartmentId={ depId }
          currentEmployeeId={ Id }
          onSelect={ (values) => setSelectedDepartment(values, roomIndex) }
        />
        { renderSelectProject() }
      </div>
    );
  };

  const renderEmployeesSelector = () => (
    <MultiSelect
      label={ LABELS.GUEST_FULL_NAME }
      items={ customersList || [] }
      values={ selectedCustomers }
      search
      searchPlaceholder={ LABELS.GUEST_FULL_NAME }
      onChange={ (values) => setSelectedCustomers(values, roomIndex) }
      overflow
    />
  );

  const renderEmployeeInfo = () => (
    <div className={ `${styles.hotel_wrap} ${styles.guest_info}` }>
      <div className={ styles.width }>
        { renderEmployeesSelector() }
      </div>
      { renderConfirmedRequestData() }
    </div>
  );

  const renderTypeOfRoomSelector = () => {
    if (!roomTypes || roomTypes.length === 0) {
      return (
        <Input
          placeholder={ LABELS.ROOM_CATEGORY }
          field={ LABELS.ROOM_CATEGORY }
          value={ selectedRooms || '' }
          onChange={ ({ target: { value } }) => changeSelectedRooms(roomIndex, value) }
          className={ styles.conditions_input }
          min='0'
        />
      );
    }

    return (
      <Select
        label={ LABELS.ROOM_CATEGORY }
        items={ roomTypes }
        value={ selectedRooms.value || '' }
        onChange={ (item) => setSelectedRooms(roomIndex, item) }
        className={ styles.type_of_room }
        theme={ THEME.BORDER }
        withLabel
        smooth
        overflow
      />
    );
  };

  const renderCommission = () => {
    const isConfirmed = status === REQUEST_STATUS_LABELS.Confirmed || requestStatus === REQUEST_STATUSES[1].value;

    if (!isConfirmed) return null;

    return (
      <div>
        <label>{ COMMISSION.LABEL }</label>
        <Input
          type='number'
          field={ COMMISSION.FIELD }
          placeholder={ LABELS.PRICE }
          value={ commission }
          onChange={ ({ target: { value } }) => setCommission(value, roomIndex) }
          className={ styles.commission_input }
        />
      </div>
    );
  };

  const renderRoomInfo = () => (
    <div className={ `${styles.hotel_wrap} ${styles.room_info}` }>

      <div className={ styles.width }>
        { renderTypeOfRoomSelector() }
      </div>
      <div className={ styles.hotel_item }>
        <Input
          type='number'
          field={ PRICE_VALUES.dailyPrice }
          placeholder={ LABELS.DAILY_PRICE }
          value={ prices.dailyPrice }
          onChange={ ({ target: { value } }) => changePrices(roomIndex, PRICE_VALUES.dailyPrice, value) }
          className={ styles.input }
        />
      </div>
      <div className={ styles.hotel_item }>
        { renderCommission() }
      </div>
    </div>
  );

  const renderCheckInAndOutDatesInfo = () => (
    <div className={ `${styles.date_pickers} ${styles.divider}` }>
      <div className={ styles.date_picker_wrap }>
        <div>{ LABELS.CHECKIN_DATE }</div>
        <DatePicker
          dateFormat={ DEFAULTDATE }
          timeFormat=''
          value={ checkinDate }
          onChange={ (value) => changeCheckDates(roomIndex, DATE_FIELDS.CHECKIN_DATE, value) }
          type='date'
          className={ styles.date_picker }
        />
      </div>
      <div className={ styles.date_picker_wrap }>
        <div>{ LABELS.CHECKOUT_DATE }</div>
        <DatePicker
          dateFormat={ DEFAULTDATE }
          timeFormat=''
          value={ checkoutDate }
          onChange={ (value) => changeCheckDates(roomIndex, DATE_FIELDS.CHECKOUT_DATE, value) }
          type='date'
          className={ styles.date_picker }
        />
      </div>
    </div>
  );

  const renderConditionCheckbox = (condition, fn, label) => (
    <Checkbox
      value={ condition }
      onChange={ () => fn(roomIndex, !condition) }
      className={ styles.checkbox_width }
    >
      { label }
    </Checkbox>
  );

  const renderHotelConditionsInput = (condition, priceName) => (condition
    ? (
      <div>
        <Input
          type='number'
          field={ LABELS.PRICE }
          placeholder={ LABELS.PRICE }
          value={ prices[priceName] }
          onChange={ ({ target: { value } }) => changePrices(roomIndex, priceName, value) }
          className={ styles.conditions_input }
          min='0'
        />
      </div>
    )
    : null);

  const renderHotelCancellationInput = (condition, priceName) => (condition
    ? (
      <div>
        <Input
          type='number'
          field={ LABELS.PRICE }
          placeholder={ LABELS.PRICE }
          value={ cancellationPenalties[priceName] }
          onChange={ ({ target: { value } }) => changeCancellationPrices(roomIndex, priceName, value) }
          className={ styles.conditions_input }
          min='0'
        />
      </div>
    )
    : null);

  const renderCheckinTime = () => (
    <div>
      <Input
        type='text'
        field={ LABELS.CHECKIN_TIME }
        placeholder={ LABELS.CHECKIN_TIME }
        value={ checkinTime }
        onChange={ ({ target: { value } }) => changeCheckinTime(roomIndex, value) }
        className={ styles.conditions_input }
      />
    </div>
  );

  const renderCheckoutTime = () => (
    <div>
      <Input
        type='text'
        field={ LABELS.CHECKOUT_TIME }
        placeholder={ LABELS.CHECKOUT_TIME }
        value={ checkoutTime }
        onChange={ ({ target: { value } }) => changeCheckoutTime(roomIndex, value) }
        className={ styles.conditions_input }
      />
    </div>
  );

  const renderHotelConditions = () => (
    <div className={ `${styles.hotel_conditions} ${styles.divider}` }>
      <div className={ styles.condition }>
        { renderConditionCheckbox(hasEarlyCheckin, changeHasEarlyCheckin, LABELS.EARLY_CHECKIN) }
        { renderHotelConditionsInput(hasEarlyCheckin, PRICE_VALUES.earlyCheckin) }
        { renderCheckinTime() }
      </div>
      <div className={ styles.condition }>
        { renderConditionCheckbox(hasLateCheckout, changeHasLateCheckout, LABELS.LATE_CHECKOUT) }
        { renderHotelConditionsInput(hasLateCheckout, PRICE_VALUES.lateCheckout) }
        { renderCheckoutTime() }
      </div>
      <div className={ styles.condition }>
        { renderConditionCheckbox(hasBreakfast, changeHasBreakfast, LABELS.BREAKFAST) }
      </div>
    </div>
  );

  const renderFreeCancellationDatePick = (condition) => (condition
    ? (
      <div
        className={ `${styles.date_picker_wrap}${styles.date_picker_wrap_free_cancellation}` }
      >
        <div>{ LABELS.FREE_CANCELLATION_DATE }</div>
        <DatePicker
          dateFormat={ DEFAULTDATE }
          timeFormat={ DEFAULTTIME }
          value={ freeCancellationDate }
          onChange={ (value) => changeCheckDates(roomIndex, DATE_FIELDS.FREE_CANCELLATION_DATE, value) }
          type='datetime'
          className={ styles.date_picker }
        />
      </div>
    )
    : null);

  const renderFreeCancellationConditions = () => {
    const clientLabel = hasFreeCancellation ? <label>{ LABELS.TOTAL_FOR_CLIENT }</label> : null;
    const smartwayLabel = hasFreeCancellation ? <label>{ LABELS.TOTAL_FOR_SMARTWAY }</label> : null;

    return (
      <div className={ `${styles.cancellation_fees} ${styles.divider}` }>
        <div className={ `${styles.free_cancellation} ${styles.cancellation_for_client}` }>
          { renderConditionCheckbox(hasFreeCancellation, changeHasFreeCancellation, LABELS.FREE_CANCELLATION) }
          { renderFreeCancellationDatePick(hasFreeCancellation) }
        </div>
        <div className={ styles.free_cancellation }>
          { clientLabel }
          { renderHotelCancellationInput(hasFreeCancellation, CANCELLATION_PENALTIES.total) }
        </div>
        <div className={ styles.free_cancellation }>
          { smartwayLabel }
          { renderHotelCancellationInput(hasFreeCancellation, CANCELLATION_PENALTIES.base) }
        </div>

      </div>
    );
  };

  const renderCustomRoomAnalytics = () => {
    if (status !== REQUEST_STATUS_LABELS.Confirmed && requestStatus !== REQUEST_STATUSES[1].value) return null;

    const analytics = accountAnalytics.filter(({ ApplyToTrip }) => !ApplyToTrip);
    if (analytics.length === 0) return null;

    return (
      <HotelAnalyticsRow
        analytics={ analytics }
        onChange={ setCustomRoomAnalytics }
        selectedAnalytics={ selectedRoomAnalytics }
        roomIndex={ roomIndex }
      />
    );
  };

  const renderRoomAnalytics = () => (
    <div className={ styles.selectors }>
      { renderCustomRoomAnalytics() }
    </div>
  );

  return (
    <div className={ styles.hotel_room_block }>
      <div className={ styles.hotel_room_info }>
        <div className={ styles.hotel_number_wrap }>
          <p>Номер { roomIndex + 1 }</p>
        </div>
        { renderEmployeeInfo() }
        { renderRoomInfo() }
        { renderCheckInAndOutDatesInfo() }
        { renderHotelConditions() }
        { renderFreeCancellationConditions() }
        { renderRoomAnalytics() }
      </div>
      <div className={ styles.hotel_room_tools }>
        { totalRooms - 1 === roomIndex && <Button
          className={ styles.button_wrap }
          label={ BUTTON_LABELS.ADD_ROOM }
          onClick={ onAddRoom }
        /> }
        <Button
          className={ styles.button_wrap }
          label={ BUTTON_LABELS.COPY_ROOM }
          onClick={ () => onCopyRoom(roomIndex) }
        />
        <Button
          className={ styles.button_wrap }
          label={ BUTTON_LABELS.DELETE_ROOM }
          onClick={ () => onDeleteRoom(roomIndex) }
          disabled={ roomIndex === 0 }
        />
      </div>
    </div>
  );
};

HotelRoomBlock.propTypes = {
  status: PropTypes.string,
  isEditing: PropTypes.bool.isRequired,
  requestStatus: PropTypes.number,
  customer: PropTypes.object,
  companyId: PropTypes.number,
  SelectedDepartmentId: PropTypes.number,
  setSelectedDepartment: PropTypes.func.isRequired,
  customersList: PropTypes.arrayOf(PropTypes.object),
  customerProjects: PropTypes.arrayOf(PropTypes.object),
  projectId: PropTypes.number,
  MandatoryProject: PropTypes.any,
  setProjectId: PropTypes.func.isRequired,
  selectedCustomers: PropTypes.arrayOf(PropTypes.number),
  setSelectedCustomers: PropTypes.func.isRequired,
  prices: PropTypes.object,
  changePrices: PropTypes.func.isRequired,
  roomTypes: PropTypes.any,
  selectedRooms: PropTypes.string,
  setSelectedRooms: PropTypes.func.isRequired,
  changeSelectedRooms: PropTypes.func.isRequired,
  commission: PropTypes.string,
  setCommission: PropTypes.func.isRequired,
  checkinDate: PropTypes.any,
  checkoutDate: PropTypes.any,
  changeCheckDates: PropTypes.func.isRequired,
  hasEarlyCheckin: PropTypes.bool,
  changeHasEarlyCheckin: PropTypes.func.isRequired,
  hasLateCheckout: PropTypes.bool,
  changeHasLateCheckout: PropTypes.func.isRequired,
  hasBreakfast: PropTypes.bool,
  changeHasBreakfast: PropTypes.func.isRequired,
  checkinTime: PropTypes.string,
  changeCheckinTime: PropTypes.func.isRequired,
  checkoutTime: PropTypes.string,
  changeCheckoutTime: PropTypes.func.isRequired,
  hasFreeCancellation: PropTypes.bool,
  changeHasFreeCancellation: PropTypes.func.isRequired,
  freeCancellationDate: PropTypes.any,
  cancellationPenalties: PropTypes.object,
  onAddRoom: PropTypes.func.isRequired,
  onDeleteRoom: PropTypes.func.isRequired,
  onCopyRoom: PropTypes.func.isRequired,
  roomIndex: PropTypes.number,
  totalRooms: PropTypes.number,
  getMandatoryProject: PropTypes.func.isRequired,
  getCustomerInfo: PropTypes.func.isRequired,
  getSelectedCustomersByIds: PropTypes.func.isRequired,
  accountId: PropTypes.number,
  handleSendNotification: PropTypes.func.isRequired,
  accountAnalytics: PropTypes.arrayOf(PropTypes.object),
  selectedRoomAnalytics: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  setCustomRoomAnalytics: PropTypes.func.isRequired,
  isTripPaidByPersonalFunds: PropTypes.bool,
};

export default HotelRoomBlock;
