import React, { useEffect } from 'react';
import { Loading } from 'sw-ui';
import { observer } from 'mobx-react-lite';

import lodashReplaces from '../../../../../../bi/utils/lodashReplaces';
import { rootService } from '../../../../../../v2/core/business';

import SelectAnalytics from '../../../../../trip/components/selectAnalytics';

import { LABELS } from '../../../../../../bi/constants/hotelBookRequest';

import styles from './index.module.css';

const HotelAnalyticsRow = observer(({
  selectedAnalytics,
  onChange,
  analytics,
  roomIndex,
}) => {
  const arrOfAnalyticsSlices = lodashReplaces.chunk(analytics, 3);
  const { loading, analyticsData } = rootService.services.analytics.customAnalytics;
  const customAnalytics = rootService.services.analytics.customAnalytics;
  const hotelAnalyticsData = analyticsData.filter(({ Id }) => selectedAnalytics.includes(Id));

  const handleChange = (value, Id) => {
    Number.isInteger(roomIndex)
      ? onChange(roomIndex, Id, value, customAnalytics)
      : onChange(Id, value, customAnalytics);
  };

  useEffect(() => {
    rootService.services.analytics.getAnalyticByValueIdWithRoomIndex(roomIndex, hotelAnalyticsData);
  }, [hotelAnalyticsData]);

  const rows = arrOfAnalyticsSlices.map((arr, i) => {
    const analyticsSlicesHtml = arr.map((item) => {
      const value = hotelAnalyticsData?.filter((data) => data.UserAnalyticsId === item.Id)[0] || {};

      return (
        <div key={ `${roomIndex}_${item.Id}` }>
          <label>{ LABELS.CUSTOM_ANALYTICS }</label>
          <SelectAnalytics
            analytics={ item }
            onSelect={ (value) => handleChange(value, item.Id) }
            value={ value }
            labelTheme='light'
          />
        </div>
      );
    });

    return (
      <div key={ i } className={ styles.row }>
        { analyticsSlicesHtml }
      </div>
    );
  });

  if (loading) {
    return (
      <Loading size='small' />
    );
  }

  return (
    <div key={ roomIndex }>
      { rows }
    </div>
  );
});

export default HotelAnalyticsRow;
