import PropTypes from 'prop-types';
import { Loading } from 'sw-ui';

import { Button } from 'components/button';

import {
  BUTTON_LABELS,
  LABELS,
  REQUEST_STATUS_LABELS,
  REQUEST_STATUSES,
} from 'bi/constants/hotelBookRequest';

import styles from './index.module.css';

const HotelFormButtons = (props) => {
  const {
    reset,
    isEditing,
    status,
    isSendingRequest,
    handleSendRequestToHotel,
    requestStatus,
    btnRequest,
    addNewTrip,
    customerEmail,
    requestUsers,
    cartId,
    handleMoveToCart,
  } = props;

  const renderSubmitButton = () => {
    const label = isEditing ? BUTTON_LABELS.SAVE_EDITED : BUTTON_LABELS.SAVE_DRAFT;

    if (status === REQUEST_STATUS_LABELS.TripAdded) return null;

    return (
      <Button
        label={ label }
        className={ styles.button_wrap }
        type='submit'
      />
    );
  };

  const renderSendRequestButton = () => {
    if (status === REQUEST_STATUS_LABELS.TripAdded) return null;

    if (isSendingRequest) {
      return (
        <div className={ styles.request_sending }>
          <Loading />
          <label>{ LABELS.SENDING_REQUEST }</label>
        </div>
      );
    }

    return (
      <Button
        label={ BUTTON_LABELS.SEND }
        className={ styles.button_wrap }
        onClick={ handleSendRequestToHotel }
      />
    );
  };

  const renderNewTripButton = () => {
    if (!(status === REQUEST_STATUS_LABELS.Confirmed || requestStatus === REQUEST_STATUSES[1].value)) return null;

    if (btnRequest) {
      return (
        <div className={ styles.new_trip_waiting }>
          <Loading />
          <label>{ LABELS.NEW_TRIP_WAITING }</label>
        </div>
      );
    }

    return (
      <Button
        label={ LABELS.NEW_TRIP }
        onClick={ addNewTrip }
        className={ styles.button_wrap }
      />
    );
  };

  const renderMoveToCartButton = () => {
    const { value } = requestUsers.find(({ label }) => label === customerEmail) || {};

    if (!value || !cartId || status === REQUEST_STATUS_LABELS.TripAdded) return null;

    return (
      <Button
        label={ BUTTON_LABELS.MOVE_TO_CART }
        className={ styles.button_wrap }
        onClick={ () => handleMoveToCart(value) }
      />
    );
  };

  return (
    <div className={ styles.form_buttons }>
      <Button
        label={ BUTTON_LABELS.RESET }
        className={ styles.button_wrap }
        type='reset'
        onClick={ reset }
      />
      { renderSubmitButton() }
      { renderSendRequestButton() }
      { renderNewTripButton() }
      { renderMoveToCartButton() }
    </div>
  );
};

HotelFormButtons.propTypes = {
  btnRequest: PropTypes.any,
  status: PropTypes.string,
  requestStatus: PropTypes.number,
  reset: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isSendingRequest: PropTypes.bool.isRequired,
  handleSendRequestToHotel: PropTypes.func.isRequired,
  addNewTrip: PropTypes.func.isRequired,
  customerEmail: PropTypes.string,
  requestUsers: PropTypes.arrayOf(PropTypes.object),
  cartId: PropTypes.number,
  handleMoveToCart: PropTypes.func.isRequired,
};

export default HotelFormButtons;
