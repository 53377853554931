import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';

import AdditionalInfoBlock from './components/AdditionalInfoBlock';
import RequestList from './components/requestsList';
import ChatInfoBlock from './components/ChatInfoBlock';
import HotelGeneralBlock from './components/HotelGeneralBlock';
import HotelFormButtons from './components/HotelFormButtons';
import HotelRoomBlock from './components/HotelRoomBlock';

import { isMoment } from 'bi/utils/formatDate';

import { rootService } from '../../../../v2/core/business';

import {
  NOTIFICATION_STATE,
  WARNINGS,
  URL_QUERY_PARAMS,
  TIMEOUT,
} from '../../../../bi/constants/hotelBookRequest';
import { KEYS } from '../../../../bi/constants/customAnalytics';

import styles from './index.module.css';

const HotelBookRequest = observer(({
  companyService,
  hotelsService,
  hotelsService: {
    changeField,
    saveHotelBookRequest,
    requestHotelInfo,
    getSavedRequests,
    setCustomersList,
    setSelectedCustomers,
    changeSelectedHotel,
    changeVatValues,
    changeHasEarlyCheckin,
    changeHasLateCheckout,
    changeHasFreeCancellation,
    changeHasBreakfast,
    changeCheckDates,
    changeCheckinTime,
    changeCheckoutTime,
    changePrices,
    changeCancellationPrices,
    changeIntercomLink,
    changeSelectedRooms,
    changeAdditionalEmail,
    changeHotelInput,
    setContacts,
    setAdditionalComment,
    setSelectedRooms,
    reset,
    deleteSavedRequest,
    changeIsEditing,
    getSavedRequest,
    saveEditedRequest,
    sendRequestToHotel,
    uploadCustomTemplate,
    resetCustomTemplate,
    changeIsSelfEmployed,
    changeRequestStatus,
    setSelectedDepartment,
    setProjectId,
    getMandatoryProject,
    getCustomerInfo,
    getSelectedCustomersByIds,
    getRequestUsers,
    createNewTripFromRequest,
    setCommission,
    setHotelId,
    setRequestUserId,
    setCustomRoomAnalytics,
    addRoom,
    deleteRoom,
    copyRoom,
    selectCustomRequestAnalytics,
  },
  searchHotelsInput,
  notificationService,
  companyId,
  accountId,
  isTripPaidByPersonalFunds,
}) => {
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const [isRequestFromCart, setIsRequestFromCart] = useState(false);
  const {
    hotelInfo: {
      hotelName,
      isSelfEmployed,
      vatRate,
    },
    hotelRates,
    additionalComment,
    customersList,
    hotelInfo,
    savedRequests,
    roomTypes,
    isAdditionalEmail,
    contacts,
    noContacts: {
      noEmail,
      noPhone,
    },
    isLoading,
    intercomLink,
    isEditing,
    customTemplate,
    editedRequest: {
      status,
      id,
      customerEmail,
      cartId,
    },
    requestStatus,
    MandatoryProject,
    customerProjects,
    btnRequest,
    requestUsers,
    requestUserId,
    newTripFailure,
    newTripSuccess,
    hotelNotFound,
    accountAnalytics,
    selectedRequestAnalytics,
    editedRequest,
  } = hotelsService.get();

  useEffect(() => {
    const requestIdFromUrl = new URL(window.location.href).searchParams.get(URL_QUERY_PARAMS.REQUEST_ID);

    if (requestIdFromUrl) {
      getSavedRequest(requestIdFromUrl, accountId);
      changeIsEditing(true);
      setIsRequestFromCart(!isRequestFromCart);

      const timer = setTimeout(() => {
        setIsRequestFromCart(false);
      }, TIMEOUT);

      return () => clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    rootService.services.analytics.extractUserAnalytics(editedRequest);
  }, [accountAnalytics]);

  useEffect(() => {
    if (requestUsers && requestUsers.length > 0) {
      return;
    }

    getRequestUsers(companyId);
  }, [companyId, id]);

  useEffect(() => {
    if (customersList && customersList.length > 0) {
      return;
    }

    const updateEmployees = async () => {
      const { employees } = await companyService.get();

      try {
        if ((JSON.stringify(customersList) !== JSON.stringify(employees)) || !customersList) {
          setCustomersList(employees);
        }
      } catch (error) {
        setCustomersList([]);
      }
    };

    companyService.employees.loadEmployees();

    const unsubscribeEmployees = companyService.subscribe(updateEmployees);

    return () => {
      if (unsubscribeEmployees) unsubscribeEmployees();
    };
  }, [companyService, customersList]);

  useEffect(() => {
    const updateHotels = (state) => hotelsService.get({ ...state });

    const unsubscribeHotels = hotelsService.subscribe(updateHotels);

    return () => {
      if (unsubscribeHotels) unsubscribeHotels();
    };
  }, [
    hotelsService,
    hotelInfo,
    savedRequests,
  ]);

  const handleSendNotification = (label, state) => {
    notificationService.send({
      header: label,
      level: notificationService.levels.get(state),
    });
  };

  useEffect(() => {
    if (noEmail && noPhone) {
      handleSendNotification(WARNINGS.NO_CONTACTS, NOTIFICATION_STATE.FAILURE);
    }
  }, [noEmail, noPhone]);

  useEffect(() => {
    if (newTripFailure) {
      handleSendNotification(WARNINGS.NEW_TRIP_FAILURE, NOTIFICATION_STATE.FAILURE);
    }

    if (newTripSuccess) {
      handleSendNotification(WARNINGS.NEW_TRIP_SUCCESS, NOTIFICATION_STATE.SUCCESS);
    }
  }, [newTripFailure, newTripSuccess]);

  useEffect(() => {
    if (hotelNotFound) {
      handleSendNotification(WARNINGS.HOTEL_NOT_FOUND, NOTIFICATION_STATE.FAILURE);
    }
  }, [hotelNotFound]);

  const checkAbsentEmployees = () => {
    const roomWithNoEmployees = hotelRates.find((hotelRate) => {
      return hotelRate.employees.length === 0;
    });

    return !!roomWithNoEmployees;
  };

  const checkCheckInOutDates = () => {
    const roomWithNoCheckDates = hotelRates.find(({ checkinDate, checkoutDate }) => {
      return !checkinDate || !checkoutDate;
    });

    const roomWithNoValidDates = hotelRates.find(({ checkinDate, checkoutDate }) => {
      return !(isMoment(checkinDate) && checkinDate.isValid()) || !(isMoment(checkoutDate) && checkoutDate.isValid());
    });

    return !!roomWithNoCheckDates || !!roomWithNoValidDates;
  };

  const checkRequiredRequestAnalytics = () => {
    const { hotelSelectedAnalytics } = rootService.services.analytics.customAnalytics;
    const requiredRequestAnalytics = accountAnalytics.filter(({ ApplyToTrip, Required }) => ApplyToTrip && Required);
    const requiredRequestAnalyticsIds = requiredRequestAnalytics.map(({ Id }) => Id);
    const isAnalyticValid = !(hotelSelectedAnalytics[KEYS.GENERAL_ANALYTICS]
      && requiredRequestAnalyticsIds.every((key) => key in hotelSelectedAnalytics[KEYS.GENERAL_ANALYTICS]));

    return !!requiredRequestAnalyticsIds.length && isAnalyticValid;
  };

  const checkRequiredRoomAnalytics = () => {
    const { hotelSelectedAnalytics } = rootService.services.analytics.customAnalytics;
    const requiredRoomAnalytics = accountAnalytics.filter(({ ApplyToTrip, Required }) => !ApplyToTrip && Required);
    const requiredRoomAnalyticsIds = requiredRoomAnalytics.map(({ Id }) => Id);
    const keysToCheck = Object.keys(hotelSelectedAnalytics).filter((key) => key !== KEYS.GENERAL_ANALYTICS);

    const isRateCountValid = hotelRates.length === keysToCheck.length;
    const isAnalyticsValid = keysToCheck.every((sectionKey) => {
      const section = hotelSelectedAnalytics[sectionKey];

      return section && requiredRoomAnalyticsIds.every((id) => id in section);
    });

    return !!requiredRoomAnalyticsIds.length && !(isRateCountValid && isAnalyticsValid);
  };

  const checkAbsentDailyPrices = () => {
    const roomWithNoDailyPrice = hotelRates.find((hotelRate) => {
      return hotelRate.price.dailyPrice === '';
    });

    return !!roomWithNoDailyPrice;
  };

  const handleSendRequest = (event) => {
    event.preventDefault();

    if (!hotelInfo.emails[0] && !contacts.email) {
      return handleSendNotification(WARNINGS.NO_EMAIL, NOTIFICATION_STATE.FAILURE);
    }

    if (!intercomLink) {
      return handleSendNotification(WARNINGS.NO_INTERCOM_LINK, NOTIFICATION_STATE.FAILURE);
    }

    if (checkAbsentEmployees()) {
      return handleSendNotification(WARNINGS.NO_CUSTOMERS, NOTIFICATION_STATE.FAILURE);
    }

    if (checkCheckInOutDates()) {
      return handleSendNotification(WARNINGS.NO_DATES, NOTIFICATION_STATE.FAILURE);
    }

    if (!hotelInfo.hotelId) {
      return handleSendNotification(WARNINGS.HOTEL_NOT_FOUND, NOTIFICATION_STATE.FAILURE);
    }

    if (isEditing) {
      saveEditedRequest(companyId, accountId);

      return handleSendNotification(WARNINGS.SUCCESS, NOTIFICATION_STATE.SUCCESS);
    }

    saveHotelBookRequest(companyId, accountId)
      .then(() => {
        getSavedRequests(companyId, accountId);
        handleSendNotification(WARNINGS.SUCCESS, NOTIFICATION_STATE.SUCCESS);
      })
      .catch(() => {
        handleSendNotification(WARNINGS.FAILURE, NOTIFICATION_STATE.FAILURE);
      });
  };

  const handleSendRequestToHotel = async () => {
    if (!hotelInfo.emails[0] && !contacts.email) {
      return handleSendNotification(WARNINGS.NO_EMAIL, NOTIFICATION_STATE.FAILURE);
    }

    if (checkAbsentEmployees()) {
      return handleSendNotification(WARNINGS.NO_CUSTOMERS, NOTIFICATION_STATE.FAILURE);
    }

    if (checkCheckInOutDates()) {
      return handleSendNotification(WARNINGS.NO_DATES, NOTIFICATION_STATE.FAILURE);
    }

    if (!hotelName) {
      return handleSendNotification(WARNINGS.NO_HOTEL_SELECTED, NOTIFICATION_STATE.FAILURE);
    }

    if (!intercomLink) {
      return handleSendNotification(WARNINGS.NO_INTERCOM_LINK, NOTIFICATION_STATE.FAILURE);
    }

    if (checkAbsentDailyPrices()) {
      return handleSendNotification(WARNINGS.NO_DAILY_PRICE, NOTIFICATION_STATE.FAILURE);
    }

    if (!hotelInfo.hotelId) {
      return handleSendNotification(WARNINGS.HOTEL_NOT_FOUND, NOTIFICATION_STATE.FAILURE);
    }

    setIsSendingRequest(true);

    try {
      const emailSubject = await sendRequestToHotel(companyId, accountId);

      handleSendNotification(WARNINGS.SEND_SUCCESS, NOTIFICATION_STATE.SUCCESS);
      handleSendNotification(WARNINGS.SUCCESS, NOTIFICATION_STATE.SUCCESS);

      if (emailSubject) {
        const email = hotelInfo.emails[0] ? hotelInfo.emails[0] : contacts.email;

        setIsSendingRequest(false);
        handleSendNotification(WARNINGS.SUCCESS_EMAIL_SUBJ(email, emailSubject), NOTIFICATION_STATE.SUCCESS);
      }
    } catch (err) {
      setIsSendingRequest(false);
      handleSendNotification(WARNINGS.SEND_FAILURE, NOTIFICATION_STATE.FAILURE);
    }
  };

  const handleMoveToCart = (userId) => {
    const path = `/account/${accountId}/company/${companyId}/user/${userId}`;

    window.open(path, '_blank');
  };

  const addNewTrip = () => {
    if (checkRequiredRequestAnalytics()) {
      return handleSendNotification(WARNINGS.NO_TRIP_ANALYTICS, NOTIFICATION_STATE.FAILURE);
    }

    if (checkRequiredRoomAnalytics()) {
      return handleSendNotification(WARNINGS.NO_ROOM_ANALYTICS, NOTIFICATION_STATE.FAILURE);
    }

    if (!requestUserId) {
      return handleSendNotification(WARNINGS.NO_INITIATOR, NOTIFICATION_STATE.FAILURE);
    }

    return createNewTripFromRequest(companyId, accountId);
  };

  const renderNewReqWarning = () => {
    if (!isRequestFromCart) return null;

    return (
      <div className={ styles.pre_send_warning }>
        { WARNINGS.CHECK_BEFORE_SEND }
      </div>
    );
  };

  return (
    <div>
      <div className={ styles.wrap }>
        <form className={ styles.form_wrap } onSubmit={ handleSendRequest }>
          <div className={ styles.form_inner }>
            { renderNewReqWarning() }
            <ChatInfoBlock
              status={ status }
              isEditing={ isEditing }
              changeIntercomLink={ changeIntercomLink }
              intercomLink={ intercomLink }
              setRequestUserId={ setRequestUserId }
              requestUsers={ requestUsers }
              requestUserId={ requestUserId }
              requestStatus={ requestStatus }
              accountAnalytics={ accountAnalytics }
              selectedRequestAnalytics={ selectedRequestAnalytics }
              selectCustomRequestAnalytics={ selectCustomRequestAnalytics }
            />
            <HotelGeneralBlock
              status={ status }
              isEditing={ isEditing }
              changeVatValues={ changeVatValues }
              searchHotelsInput={ searchHotelsInput }
              selectedHotel={ hotelName }
              changeField={ changeField }
              requestHotelInfo={ requestHotelInfo }
              changeSelectedHotel={ changeSelectedHotel }
              changeHotelInput={ changeHotelInput }
              contacts={ contacts }
              setContacts={ setContacts }
              isAdditionalEmail={ isAdditionalEmail }
              changeAdditionalEmail={ changeAdditionalEmail }
              isSelfEmployed={ isSelfEmployed }
              changeIsSelfEmployed={ changeIsSelfEmployed }
              requestStatus={ requestStatus }
              changeRequestStatus={ changeRequestStatus }
              hotelInfo={ hotelInfo }
              vatAmount={ vatRate }
              setHotelId={ setHotelId }
            />
            { hotelRates.map((hotelRate, index, arr) => (
              <HotelRoomBlock
                key={ index }
                status={ status }
                isEditing={ isEditing }
                requestStatus={ requestStatus }
                customer={ hotelRate.customers[0] }
                companyId={ companyId }
                SelectedDepartmentId={ hotelRate.departmentId }
                setSelectedDepartment={ setSelectedDepartment }
                customersList={ customersList }
                customerProjects={ customerProjects }
                projectId={ hotelRate.projectId }
                MandatoryProject={ MandatoryProject }
                setProjectId={ setProjectId }
                selectedCustomers={ hotelRate.employees }
                setSelectedCustomers={ setSelectedCustomers }
                prices={ hotelRate.price }
                changePrices={ changePrices }
                changeCancellationPrices={ changeCancellationPrices }
                cancellationPenalties={ hotelRate.amenities.cancellationPenalties[0] }
                roomTypes={ roomTypes }
                selectedRooms={ hotelRate.roomCategory }
                setSelectedRooms={ setSelectedRooms }
                changeSelectedRooms={ changeSelectedRooms }
                commission={ hotelRate.price.commission }
                setCommission={ setCommission }
                checkinDate={ hotelRate.checkinDate }
                checkoutDate={ hotelRate.checkoutDate }
                changeCheckDates={ changeCheckDates }
                hasEarlyCheckin={ hotelRate.earlyCheckin }
                changeHasEarlyCheckin={ changeHasEarlyCheckin }
                hasLateCheckout={ hotelRate.lateCheckout }
                changeHasLateCheckout={ changeHasLateCheckout }
                hasBreakfast={ hotelRate.amenities.hasMeal }
                changeHasBreakfast={ changeHasBreakfast }
                checkinTime={ hotelRate.checkinTime }
                changeCheckinTime={ changeCheckinTime }
                checkoutTime={ hotelRate.checkoutTime }
                changeCheckoutTime={ changeCheckoutTime }
                hasFreeCancellation={ hotelRate.amenities.hasFreeCancellation }
                changeHasFreeCancellation={ changeHasFreeCancellation }
                freeCancellationDate={ hotelRate.amenities.freeCancellationDate }
                onAddRoom={ addRoom }
                onDeleteRoom={ deleteRoom }
                onCopyRoom={ copyRoom }
                roomIndex={ index }
                totalRooms={ arr.length }
                getMandatoryProject={ getMandatoryProject }
                getCustomerInfo={ getCustomerInfo }
                getSelectedCustomersByIds={ getSelectedCustomersByIds }
                accountId={ accountId }
                handleSendNotification={ handleSendNotification }
                accountAnalytics={ accountAnalytics }
                selectedRoomAnalytics={ hotelRate.userAnalytics }
                setCustomRoomAnalytics={ setCustomRoomAnalytics }
                isTripPaidByPersonalFunds={ isTripPaidByPersonalFunds }
              />)) }
            <AdditionalInfoBlock
              customTemplate={ customTemplate }
              uploadCustomTemplate={ uploadCustomTemplate }
              resetCustomTemplate={ resetCustomTemplate }
              additionalComment={ additionalComment }
              setAdditionalComment={ setAdditionalComment }
            />
          </div>
          <HotelFormButtons
            btnRequest={ btnRequest }
            status={ status }
            requestStatus={ requestStatus }
            reset={ reset }
            isEditing={ isEditing }
            isSendingRequest={ isSendingRequest }
            handleSendRequestToHotel={ handleSendRequestToHotel }
            addNewTrip={ addNewTrip }
            customerEmail={ customerEmail }
            requestUsers={ requestUsers }
            cartId={ cartId }
            handleMoveToCart={ handleMoveToCart }
          />
        </form>
      </div>
      <div className={ styles.wrap }>
        <RequestList
          companyId={ companyId }
          accountId={ accountId }
          savedRequests={ savedRequests }
          customers={ customersList }
          isLoading={ isLoading }
          onDelete={ deleteSavedRequest }
          loadRequests={ getSavedRequests }
          changeIsEditing={ changeIsEditing }
          onEdit={ getSavedRequest }
        />
      </div>
    </div>
  );
});

HotelBookRequest.propTypes = {
  companyId: PropTypes.number.isRequired,
  accountId: PropTypes.number.isRequired,
  companyService: PropTypes.object.isRequired,
  hotelsService: PropTypes.object.isRequired,
  searchHotelsInput: PropTypes.object.isRequired,
  notificationService: PropTypes.object.isRequired,
  isTripPaidByPersonalFunds: PropTypes.bool,
};

export default HotelBookRequest;
